import React, {type ChangeEvent, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {getMessageFromError} from "@/global/helpers/getMessageFromError";
import {
    apiCertificateApplication,
    apiCreateCertificateApplication,
    apiLastCertificateByIIn
} from "@/modules/certificates/services/certificates.service";
import {apiTransactionFreedomWebhook} from "@/modules/transactions/services/transactions.services";
import type {CertificateApplicationCreateDto} from "@/global/api/gen/ts/CertificateApplicationCreateDto";
import {Alert, CircularProgress, Stack} from "@mui/material";
import {BackLink} from "@/global/components/BackLink";
import {useSearchParams} from "react-router-dom";
import {IinAgreeForm} from "@/modules/certificates/pages/buying/components/CertificateBuyingStepper/IinAgreeForm";
import {
    CheckCertificateStep
} from "@/modules/certificates/pages/buying/components/CertificateBuyingStepper/CheckCertificateStep";
import {
    StartTransactionNewCertificate
} from "@/modules/certificates/pages/buying/components/CertificateBuyingStepper/StartTransactionNewCertificate";
import {
    CertificateTransactionStep
} from "@/modules/certificates/pages/buying/components/CertificateBuyingStepper/CertificateTransactionStep";
import {
    EndTransactionNewCertificate
} from "@/modules/certificates/pages/buying/components/CertificateBuyingStepper/EndTransactionNewCertificate";
import {useQueryClient} from "@tanstack/react-query";

type Steps =
    'input'
    | 'check'
    | 'startTransactionAIS'
    | 'transaction'
    | 'endTransactionAIS';

export function VirtualCertificateBuyingStepper() {
    const [step, setStep] = useState<Steps>('input');
    const [searchParams, setSearchParams] = useSearchParams();
    const iinSP = searchParams.get('iin');
    const [iin, setIin] = useState<string>(iinSP ?? '');
    const [payment, setPayment] = useState(false);

    const [agreed, setAgreed] = useState<boolean>(false);

    const {data: certificateInfo, isLoading: certificateInfoLoading, refetch} = apiLastCertificateByIIn(iin, {
        enabled: false,
        retry: false,
    });

    useEffect(() => {
        const handleIinSP = async () => {
            await handleFetchStudents();
            searchParams.delete('iin');
            setSearchParams(searchParams, {
                replace: true
            });
        };
        if (iinSP) {
            handleIinSP();
        }
    }, [iinSP]);

    const QueryClient = useQueryClient();

    const {mutate, data: applicationCertificate, isPending, reset} = apiCreateCertificateApplication();

    const {
        data: dataCertificateApplication,
        isLoading: isPendingApplication
    } = apiCertificateApplication(applicationCertificate?.id ?? certificateInfo?.certificate_application_id ?? 0);

    const {data: dataTransaction, isLoading: isLoadingTransaction} = apiTransactionFreedomWebhook(dataCertificateApplication?.transaction?.invoice_id, payment);

    useEffect(() => {
        if (dataTransaction && dataTransaction.status === 1) {
            setStep('transaction');
            return;
        }
        if (dataTransaction && dataTransaction.status === 2) {
            setPayment(true);
            setStep('endTransactionAIS');
            if (!certificateInfo?.certificate_application_id) {
                toast.success('Транзакция проведена');
            }
            return;
        }
        if (dataTransaction && (dataTransaction.status === 3 || dataTransaction.status === 10)) {
            setStep('input');
            toast.error('Ошибка транзакции. Повторите позже.');
            return;
        }
    }, [dataTransaction]);

    const handleFetchStudents = async () => {
        const res = await refetch();
        if (res.isError) {
            toast.error(`Ошибка при получение сертификата: ${getMessageFromError(res.error)}`);
            setIin('');
            return;
        }
        if (!res.data?.certificate_application_id) {
            setStep('check');
        }
    };

    const handleIinChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const sendIin = e.currentTarget.value.replace(/\D/g, '');
        if (sendIin.length > 12) {
            return;
        }
        setIin(sendIin);
    };

    const returnFirstStep = async () => {
        setStep('input');
        setIin('');
        setAgreed(false);
        setPayment(false);
        reset();
        await QueryClient.resetQueries();
    };

    const handleStepStartTransaction = () => {
        if (!certificateInfo) {
            return;
        }
        setStep('startTransactionAIS');
    };

    const onStartTransaction = (data: Omit<CertificateApplicationCreateDto, 'certificate_number' | 'iin'>) => {
        if (!certificateInfo) {
            setStep('input');
            return;
        }

        const sendData: CertificateApplicationCreateDto = {
            ...data,
            certificate_number: certificateInfo.certificate_number,
            iin
        };

        mutate(sendData, {
            onSuccess: () => {
                setStep('transaction');
            }
        });
    };

    if (step === 'input' && iinSP) {
        return <CircularProgress/>;
    }

    if (step === 'input') {
        return (
            <IinAgreeForm
                iin={iin}
                handleIinChange={handleIinChange}
                agreed={agreed}
                setAgreed={setAgreed}
                handleFetchStudents={handleFetchStudents}
                studentsByIINLoading={certificateInfoLoading || isPendingApplication || isLoadingTransaction}
            />
        );
    }

    if (step === 'check' && certificateInfo) {
        return (
            <CheckCertificateStep
                studentsByIIN={certificateInfo}
                returnFirstStep={returnFirstStep}
                handleStepStartTransaction={handleStepStartTransaction}
            />
        );
    }

    if (step === 'startTransactionAIS' && certificateInfo) {
        return (
            <StartTransactionNewCertificate
                returnFirstStep={returnFirstStep}
                isPending={isPending}
                onSubmit={onStartTransaction}
                certificateInfo={certificateInfo}
            />
        );
    }

    if (step === 'transaction') {
        return (
            <CertificateTransactionStep
                dataCertificateApplication={dataCertificateApplication}
                returnFirstStep={returnFirstStep}
            />
        );
    }

    if (step === 'endTransactionAIS' && dataCertificateApplication) {
        return (
            <EndTransactionNewCertificate
                certificateInfo={dataCertificateApplication}
                returnFirstStep={returnFirstStep}
            />
        );
    }

    return (
        <>
            <BackLink
                label={'Вернуться к вводу ИИН'}
                onClick={returnFirstStep}
                sx={{
                    alignSelf: 'flex-start'
                }}
            />
            <Stack
                display={'flex'}
                flexDirection={'column'}
                sx={{
                    width: {lg: '60%', xs: '100%'},
                    borderRadius: '26px',
                    backgroundColor: '#FFFFFF',
                    padding: 3,
                    gap: '16px'
                }}
            >
                <Alert severity={'error'}>
                    Ошибка при получение свидетельства. Повторитe, пожалуйста, позже
                </Alert>
            </Stack>
        </>
    );
}